const bucketName = 'hodo-resource'
const aws = window.AWS
const prefix = 'resources/imagePrint/'
const region = 'ap-southeast-1'
const IdentityPoolId = 'ap-southeast-1:ff53cfbf-2595-4ade-83b8-43e3ad25f279'

aws.config.region = region // 1. Enter your region
aws.config.credentials = new aws.CognitoIdentityCredentials({
  IdentityPoolId: IdentityPoolId // 2. Enter your identity pool
})
aws.config.credentials.get(function (err) {
  if (err) alert(err)
})

const uploadImagePrint = {
  async fileUploadHandler (base64) {
    if (!base64 || !base64.length) return

    const raw = base64.replace(/^data:image\/\w+;base64,/, '')
    // eslint-disable-next-line new-cap
    const base64Data = new Buffer.from(raw, 'base64')
    const type = base64.split(';')[0].split('/')[1]
    const res = await this.s3ResourcesUploader(prefix, base64Data, type)
    return res
  },
  async s3ResourcesUploader (path, base64Data, type) {
    if (!base64Data || !base64Data.length) return

    try {
      let bucket = null

      bucket = new aws.S3({
        params: {
          Bucket: bucketName
        }
      })

      const params = {
        Bucket: bucketName,
        Key: `${path}${Date.now()}.${type}`, // type is not required
        Body: base64Data,
        ACL: 'public-read',
        ContentEncoding: 'base64', // required
        ContentType: `image/${type}` // required. Notice the back ticks
      }
      const { Location } = await bucket.upload(params).promise()
      return Location
    } catch (error) {
      console.log(error)
    }
  }
}

export default uploadImagePrint

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import viMessage from '../lang/vi.json'
import enMessage from '../lang/en.json'
import frMessage from '../lang/fr.json'
const messages = {
  vi: viMessage,
  en: enMessage,
  fr: frMessage
}

Vue.use(VueI18n)

export default new VueI18n({
  locale: Vue.prototype.$lang,
  messages: messages,
  fallbackLocale: 'vi',
  silentFallbackWarn: true
})

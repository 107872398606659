const timeUtils = {

  formatTimeToHm (date) {
    return window.moment(date, 'HH:mm:ss').format('HH:mm')
  },
  formatDateTimeToStrikeDMY (date) {
    return window.moment(date).format('DD-MM-YYYY')
  },
  getTimeStringDetail (room) {
    if (room && room.repeat_type) {
      if (room.repeat_type === 1) {
        const time = window.moment(room.start_time, 'HH:mm:ss').format('HH:mm')
        return room.start_time ? time + ' - Hàng ngày' : 'Hàng ngày'
      }
      if (room.repeat_type === 2) {
        const time = window.moment(room.start_time, 'HH:mm:ss').format('HH:mm')
        const r = room.start_time ? time + ' - ' + this.getNameOfDay(window.moment(room.date).day()) + ' hàng tuần' : '' + this.getNameOfDay(window.moment(room.date).day()) + ' hàng tuần'
        return r
      }
    } else {
      return room.start_time ? this.createDateTime(room.date, room.start_time).format('HH:mm - DD/MM/YYYY') : window.moment(room.date).format('DD/MM/YYYY')
    }
  },
  getNameOfDay (day) {
    return day === 0 ? 'Chủ nhật' : day === 1 ? 'Thứ 2' : day === 2 ? 'Thứ 3' : day === 3 ? 'Thứ 4' : day === 4 ? 'Thứ 5' : day === 5 ? 'Thứ 6' : day === 6 ? 'Thứ 7' : ''
  },
  createDateTime (dateStr, timeStr) {
    const date = window.moment(dateStr)
    const time = window.moment(timeStr, 'HH:mm:ss')

    date.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second')
    })
    return date
  }
}

export default timeUtils

export const DOCUMENT_SIGN_TYPES = {
  CODE: {
    CONSULTATION: 1,
    INDICATION: 2,
    PRESCRIPTION: 3,
    INDICATION_ORDER: 4,
    DISEASE_OPERATION: 5
  }
}

export const DEFAULT_VIDEO_CONSTRAINTS = {
  width: 1280,
  height: 720,
  frameRate: 60
}

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'HodoMeet-selectedAudioInput'
export const SELECTED_AUDIO_OUTPUT_KEY = 'HodoMeet-selectedAudioOutput'
export const SELECTED_VIDEO_INPUT_KEY = 'HodoMeet-selectedVideoInput'

export const SIGN_MDT_DISEASE = {
  BBHC: 5,
  TTTQM: 6
}

export const ENVELOPE_DOCUMENT_TYPE = {
  EXAMINATION: 1, // PHIẾU KHÁM
  PRESCRIPTION: 2, // ĐƠN THUỐC
  INDICATION_RESULT: 3, // CHỈ ĐỊNH
  SURGERY_FORM: 4, // PHIẾU PHẪU THUẬT THỦ THUẬT
  TREATMENT_FORM: 5, // Phiếu điều trị
  CARE_FORM: 6, // Phiếu chăm sóc
  NUTRITIONAL_FORM: 7, // Phiếu đánh giá dinh dưỡng
  CONFIRM_TREATMENT: 8, // Phiếu xác nhận điều trị
  ADMINISTRATIVE_FORM: 9, // Biểu mẫu hành chính
  SUMMARY_TREATMENT_FORM: 10, // Phiếu sơ kết điều trị
  MDT_FORM: 11, // Biên bản hội chẩn
  PRE_OPERATIVE_ANESTHESIA_FORM: 12, // Phiếu gây mê trước mổ
  OPERATION_FORM: 13 // Tóm tắt thông qua mổ
}

export const ENVELOPE_TYPE = {
  SELF_SIGN: 1,
  SEND_SIGN: 2
}

export const SIGNATORY_ROLE = {
  signer: 1,
  follower: 2
}

export const SIGNATORY_STATUS = {
  wait_sign: 1,
  signed: 2
}

export const SIGN_ENVELOPE_STATUS = {
  draft: 1,
  processing: 2,
  completed: 3
}

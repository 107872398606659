import Vue from 'vue'
import i18n from '../utils/i18n'
const diseaseStatus = [
  { stt: 0, text: 'Chưa có kết luận', class: 'text-warning' },
  { stt: 1, text: 'Đang hội chẩn', class: 'txt-pri' },
  { stt: 2, text: 'Đã có kết luận', class: 'txt-pri' }
]

const plMdtCaseStatus = [
  { stt: 0, text: 'Chưa có kết luận', class: 'text-warning' },
  { stt: 1, text: 'Đã có kết luận', class: 'txt-pri' },
  { stt: 2, text: 'Thông qua', class: 'txt-pri' },
  { stt: 3, text: 'Không thông qua', class: 'text-danger' }
]
const mdtUtils = {
  getTimeStringDetail (room, lang = null) {
    if (room && room.repeat_type) {
      if (room.repeat_type === 1) {
        const time = window.moment(room.start_time, 'HH:mm:ss').format('HH:mm')
        return room.start_time
          ? time + ' - ' + i18n.t('daily', lang || Vue.prototype.$lang)
          : i18n.t('daily', lang || Vue.prototype.$lang)
      }
      if (room.repeat_type === 2) {
        const time = window.moment(room.start_time, 'HH:mm:ss').format('HH:mm')
        const r = room.start_time
          ? time +
            ' - ' +
            this.getNameOfDay(window.moment(room.date).day(), lang) +
            ' ' +
            i18n.t('weekly', lang || Vue.prototype.$lang)
          : '' +
            this.getNameOfDay(window.moment(room.date).day()) +
            ' ' +
            i18n.t('weekly', lang || Vue.prototype.$lang)
        return r
      }
    } else {
      return room.start_time
        ? this.createDateTime(room.date, room.start_time).format(
          'HH:mm - DD/MM/YYYY'
        )
        : window.moment(room.date).format('DD/MM/YYYY')
    }
  },
  getNameOfDay (day, lang = null) {
    return day === 0
      ? i18n.t('sunday', lang || Vue.prototype.$lang)
      : day === 1
        ? i18n.t('monday', lang || Vue.prototype.$lang)
        : day === 2
          ? i18n.t('tuesday', lang || Vue.prototype.$lang)
          : day === 3
            ? i18n.t('wednesday', lang || Vue.prototype.$lang)
            : day === 4
              ? i18n.t('thursday', lang || Vue.prototype.$lang)
              : day === 5
                ? i18n.t('friday', lang || Vue.prototype.$lang)
                : day === 6
                  ? i18n.t('saturday', lang || Vue.prototype.$lang)
                  : ''
  },
  createDateTime (dateStr, timeStr) {
    const date = window.moment(dateStr)
    const time = window.moment(timeStr, 'HH:mm:ss')

    date.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second')
    })
    return date
  },
  linkRoom (room) {
    if (!room) return
    return process.env.VUE_APP_BASE_URL + '#/mdt/' + room.room_name
  },
  getRoleName (r, lang = null) {
    return r === 1
      ? i18n.t('mdt_room.lbl_mems_preside', lang || Vue.prototype.$lang)
      : r === 2
        ? i18n.t('role_member', lang || Vue.prototype.$lang)
        : r === 3
          ? i18n.t('role_expert', lang || Vue.prototype.$lang)
          : r === 4
            ? i18n.t('role_secretary', lang || Vue.prototype.$lang)
            : ''
  },
  getPersonInfoString (p) {
    return (
      p.name +
      ' (' +
      this.getGender(p.gender) +
      ' - ' +
      (p.birthday ? ' - ' + this.getAges(p.birthday) : '') +
      (p.address ? ' - ' + p.address : '') +
      ')'
    )
  },
  getMdtPersonInfoString (p, lang = null) {
    const pname = p.patient_name || ''
    const dob = p.alias
      ? p.alias.alias_age
        ? ' - ' + this.getAges(p.alias.alias_age, lang)
        : ''
      : p.patient_birthday
        ? ' - ' + this.getAges(new Date(p.patient_birthday), lang)
        : ''
    const pinfo =
      (p.patient_gender ? this.getGender(p.patient_gender, lang) : '') +
      dob +
      (p.patient_address ? ' - ' + p.patient_address : '')
    return pname + (pinfo ? ' (' + pinfo + ')' : '')
  },
  getMdtPersonInfoString2 (p, lang = null) {
    const pname = p.patient_name || ''
    const dob = p.alias
      ? p.alias.alias_age
        ? ' - ' + this.getAges(p.alias.alias_age, lang)
        : ''
      : p.patient_birthday
        ? ' - ' + this.getAges(new Date(p.patient_birthday), lang)
        : ''
    const pinfo =
      (p.patient_gender ? this.getGender(p.patient_gender, lang) : '') + dob
    return pname + (pinfo ? ' (' + pinfo + ')' : '')
  },
  getGender (gender = 1, lang = null) {
    return gender === 1
      ? i18n.t('gender_m', lang || Vue.prototype.$lang)
      : gender === 2
        ? i18n.t('gender_fm', lang || Vue.prototype.$lang)
        : i18n.t('gender_o', lang || Vue.prototype.$lang)
  },
  getGenderWithOutLang (gender = 1, lang = null) {
    return gender === 1
      ? 'Nam'
      : gender === 2
        ? 'Nữ'
        : 'Khác'
  },
  getAges (value, lang = null) {
    if (!value) return

    if (value instanceof Date) {
      let bd = window.moment(value, 'YYYY-MM-DD')
      let age = window.moment().diff(bd, 'years')
      let month = 0
      if (!age) {
        month = window.moment().diff(bd, 'months')
      }
      return age
        ? age +
            ' ' +
            i18n.t('mdt_room.lbl_yearsold', lang || Vue.prototype.$lang)
        : month + ' ' + i18n.t('month_of_age', lang || Vue.prototype.$lang)
    } else {
      return `${value} ${i18n.t(
        'mdt_room.lbl_yearsold',
        lang || Vue.prototype.$lang
      )}`
    }
  },
  getAgesWithOutLang (value, lang = null) {
    if (!value) return

    if (value instanceof Date) {
      let bd = window.moment(value, 'YYYY-MM-DD')
      let age = window.moment().diff(bd, 'years')
      let month = 0
      if (!age) {
        month = window.moment().diff(bd, 'months')
      }
      return age
        ? age +
            ' ' +
            'tuổi'
        : month + ' ' + 'tháng tuổi'
    } else {
      return `${value} tuổi`
    }
  },
  getNumberAges (value) {
    if (!value) return

    if (value instanceof Date) {
      let bd = window.moment(value, 'YYYY-MM-DD')
      let age = window.moment().diff(bd, 'years')
      return age
    } else {
      return value
    }
  },
  getStatusDisease (stt) {
    const result = diseaseStatus[stt]
    return result || diseaseStatus[0]
  },
  getStatusPublicMdtCase (stt) {
    const result = plMdtCaseStatus[stt]
    return result || plMdtCaseStatus[0]
  },
  getDateNextWeekByDay (day) {
    return window.moment().isoWeekday(day).add(1, 'weeks')
  }
}

export default mdtUtils
